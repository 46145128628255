import { useEffect } from 'react';
import { useRouter } from 'next/router';

function ScrollToTop() {
  const router = useRouter();
  const { pathname, asPath } = router;

  useEffect(() => {
    // if (state?.disableToTop) {
    //   router.push(pathname, asPath, { state: {} });
    //   return;
    // }
    if (window.pageYOffset) window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

export { ScrollToTop };
