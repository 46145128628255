import { useState, useEffect, useCallback } from "react";

const MEDIA_SIZES = [
  { firstSize: 500, lastSize: 810, count: 2 },
  { firstSize: 810, lastSize: 1100, count: 3 },
  { firstSize: 1100, lastSize: +Infinity, count: 4 },
];

export const useShowByMedia = (currentCount, mediaSizes = MEDIA_SIZES) => {
  const [activeMediaSize, setActiveMediaSize] = useState(null);

  const handler = useCallback(() => {
    const windowWidth = document.body.offsetWidth;
    if (
      !activeMediaSize ||
      windowWidth < activeMediaSize.firstSize ||
      windowWidth > activeMediaSize.lastSize
    ) {
      const activeMediaEl = mediaSizes.find(
        ({ firstSize, lastSize }) =>
          windowWidth > firstSize && windowWidth <= lastSize
      );

      setActiveMediaSize(activeMediaEl);
    }
  }, [activeMediaSize, mediaSizes]);

  useEffect(() => {
    if (!activeMediaSize) return handler();

    window.addEventListener("resize", handler);

    return () => window.removeEventListener("resize", handler);
  }, [activeMediaSize]);

  return currentCount > activeMediaSize?.count;
};
