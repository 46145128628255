import { useEffect, useRef, useState, useCallback } from 'react';

export const useTimer = (start = false, seconds = 180000) => {
  const [time] = useState(Date.parse((new Date().toString())) + seconds);
  const intervalRef: { current: NodeJS.Timeout | null } = useRef(null);

  const calculateTimeLeft = useCallback(() => {
    let timeLeft = {};
    if (!start) return timeLeft;
    const difference = time - Date.parse(new Date().toString());

    if (difference > 0) {
      timeLeft = {
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        //   .toString()
        //   .padStart(2, 0),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60),
        date: time,
      };
    }

    return timeLeft;
  }, []);

  const [timer, setTimer] = useState(calculateTimeLeft);

  useEffect(() => {
    intervalRef.current = setInterval(() => {
      setTimer((_) => calculateTimeLeft());
    }, 1000);

    return () => clearInterval(intervalRef.current as NodeJS.Timeout);
  }, []);

  useEffect(() => {
    if (Object.values<Date>(timer).every((i) => +i <= 0))
      clearInterval(intervalRef.current as NodeJS.Timeout);
  }, [timer]);

  return timer;
};
