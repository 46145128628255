import React, { memo } from "react";

import { OptionCard } from "../OptionCard/OptionCard";

import { useOption } from "../../context/optionContext";
import { CardButton } from "../../../../../../common/CardButton/CardButton";
import { AlertBlock } from "../../../../../../common/AlertBlock";
import { useTranslation } from "next-i18next";

const OptionPaymentAlert = memo(({ title, availableOptions, onClose }) => {
  const { addOption, removeOption } = useOption();
  const [t] = useTranslation();

  return (
    <AlertBlock onClose={onClose} initialWidth>
      <>
        <h2 className="sign-up-section__title">{title}</h2>
        {availableOptions.map(({ id, ordered, optionId, ...props }) => (
          <OptionCard {...props} key={id}>
            <div className="button-wrap">
              {ordered ? (
                <CardButton
                  className={"primary-button primary-button--reverse"}
                  onClick={() => removeOption(id)}
                  title={t("remove")}
                />
              ) : (
                <CardButton
                  className={"primary-button"}
                  onClick={() => addOption({ id, ordered: true, ...props })}
                  title={t("add")}
                />
              )}
            </div>
          </OptionCard>
        ))}
      </>
    </AlertBlock>
  );
});

export { OptionPaymentAlert };
