import React, { memo } from "react";
import { OptionProvider } from "../context/optionContext";
import PaymentBlock from "./PaymentBlock";

const PaymentBlockContainer = memo((props) => {
  return (
    <OptionProvider>
      <PaymentBlock {...props} />
    </OptionProvider>
  );
});

export { PaymentBlockContainer };
