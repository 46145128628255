import React, { useMemo, useState } from "react";
import { AlertBlock } from "../AlertBlock";

import ConfirmPhoneEnter from "./ConfirmPhoneEnter";

import Phone3Code from "./Phone3Code";

function ConfirmPhone({ buttonChild, isEmailConfirmModal, onClose }) {
  const [phone, setPhone] = useState("");
  const [activeWindow, setActiveWindow] = useState(
    isEmailConfirmModal ? "window4" : "enter"
  );

  const { prevButton, nextWindow } = useMemo(() => {
    return {
      prevButton: () => setActiveWindow("enter"),

      // nextWindow: () => (isEmailConfirmModal ? setActiveWindow("window4") : onClose()),
    };
  }, []); //showNextModal, showNextModal

  return (
    <AlertBlock onClose={onClose}>
      {
        (activeWindow === "enter" && (
          <ConfirmPhoneEnter
            phone={phone}
            setPhone={setPhone}
            setActiveWindow={setActiveWindow}
          />
        )) ||
          (activeWindow === "submit" && (
            <Phone3Code
              onClickPrevButton={prevButton}
              setActiveWindow={onClose}
              phoneRequired={false}
            />
          ))

        //! может в будущем добавят

        //  || (
        //   <>
        //     <div className="payment-card">
        //       <div className="payment-card__wrapper">
        //         {buttonChild({
        //           clas: "sign-up-section__reg-button",
        //           finishFunc: onClose,
        //         })}
        //       </div>
        //     </div>
        //   </>
        // )
      }
    </AlertBlock>
  );
}

export default ConfirmPhone;
