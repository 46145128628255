import { useState } from "react";

export const useInput = (initialValue, onChange = Function.prototype) => {
  const [value, setValue] = useState(initialValue);

  return {
    value,
    //setValue,

    //reset: () => setValue(""),
    bind: {
      value,
      onChange: (event) => setValue(event.target.value),
    },
  };
};
