import {
  useEffect,
  useState,
  useRef,
  RefObject,
  MutableRefObject,
} from "react";

const useOnScreen = <T extends Element>(ref: MutableRefObject<T>) => {
  // const observerRef = useRef<IntersectionObserver | null>(null);
  const [isOnScreen, setIsOnScreen] = useState(false);

  useEffect(() => {}, []);

  useEffect(() => {
    const observer = new IntersectionObserver(([entry]) =>
      setIsOnScreen(entry.isIntersecting)
    );

    if (ref.current) {
      observer.observe(ref.current);
    }

    return () => {
      if (ref.current) observer.unobserve(ref.current);
    };
  }, [ref]);

  return isOnScreen;
};

export { useOnScreen };
