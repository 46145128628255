import React, { useState, useCallback, useRef } from "react";

import SignUpAlertBlock from "./SignUpAlertBlock";

import { useTranslation } from "next-i18next";
import { useRouter } from "next/router";
import { EMAIL_UPDATE } from "../../../graphql/mutations";
import { validateEmail, passValidation } from "./validationConstants";
import { SITE_URL } from "../../../constants/APIKeys";

import { useMutation } from "@apollo/client";

function Window4({ onClickSkipButton, setActiveWindow, setEmail, email }) {
  const [t] = useTranslation();
  const router = useRouter();
  const {
    asPath: activeUrl,
    query: { club },
  } = router;

  const emailRef = useRef(email);
  const firstPassRef = useRef(null);
  const secondPassRef = useRef(null);

  const [emailErrorMessage, setEmailErrorMessage] = useState("");
  const [firstPassErrorMessage, setFirstPassErrorMessage] = useState("");
  const [secondPassErrorMessage, setSecondPassErrorMessage] = useState("");

  const inputsArray = [
    {
      inputType: "email",
      inputRef: emailRef,
      placeholderValue: t("email"),
      errorMessage: emailErrorMessage,
    },
    {
      isRequired: true,
      inputType: "password",
      inputRef: firstPassRef,
      // value: firstPassValue,
      // name: "firstPassValue",
      // onChange,
      placeholderValue: t("password"),
      errorMessage: firstPassErrorMessage,
    },
    {
      isRequired: true,
      inputType: "password",
      inputRef: secondPassRef,
      // value: secondPassValue,
      // name: "secondPassValue",
      // onChange,
      placeholderValue: t("confirm_password"),
      errorMessage: secondPassErrorMessage,
    },
  ];

  const [emailUpdate] = useMutation(EMAIL_UPDATE, {
    fetchPolicy: "network-only",
  });

  const onClickNextButton = useCallback(() => {
    setEmailErrorMessage("");

    const {
      current: { value: emailValue },
    } = emailRef;

    const {
      current: { value: firstPassValue },
    } = firstPassRef;
    const {
      current: { value: secondPassValue },
    } = secondPassRef;

    let isError = false;

    const wrongEmailError = t("wrong E-mail");

    // email
    if (!emailValue.trim() && !secondPassValue.trim()) {
      // Если поле email пустое то пропускаем добавление email
      isError = true;
      onClickSkipButton();
    } else if (!validateEmail(emailValue)) {
      isError = true;
      setEmailErrorMessage(wrongEmailError);
    }

    const requiredError = t("required_field");

    const passesNotSame = t("password_mismatch");

    passValidation({
      passValue: firstPassValue,
      setPassErrorMessage: setFirstPassErrorMessage,
      requiredError,
    }) && (isError = true);

    passValidation({
      passValue: secondPassValue,
      setPassErrorMessage: setSecondPassErrorMessage,
      requiredError,
    }) && (isError = true);

    if (firstPassValue !== secondPassValue) {
      isError = true;
      setSecondPassErrorMessage(passesNotSame);
    }

    if (isError) return;

    const url = club
      ? `${SITE_URL}/club/${club}/confirmation`
      : `${SITE_URL}/confirmation`;
    // nextUrl ? `${SITE_URL}${nextUrl}` :
    // `${SITE_URL}${activeUrl}?login_sms=true`;

    emailUpdate({
      variables: {
        email: emailValue,
        password: firstPassValue,
        nextUrl: url, //getNextUrl(url),
      },
    }).then(
      ({ data: { emailUpdate }, errors: { result } = {} }) => {
        setEmail(emailRef.current.value);

        if (emailUpdate) return setActiveWindow("window6");
        if (result == 3) return setActiveWindow("window5");
      },
      ({ graphQLErrors, message }) => {
        setEmail(emailRef.current.value);
        if (graphQLErrors?.[0]?.result == 3) return setActiveWindow("window5");

        setSecondPassErrorMessage(message);
      }
    );
  }, []);

  return (
    <SignUpAlertBlock
      title={t("add_email")}
      nextButtonTitle={t("next")}
      inputsArray={inputsArray}
      onClickNextButton={onClickNextButton}
    />
  );
}

export default Window4;
