import React, { useCallback } from "react";
import { useRouter } from "next/router";
import { PAY_CARD_FROM_ACCOUNT } from "../../../../../../graphql/mutations/mutationPay";
import { GET_PROFILE } from "../../../../../../graphql/queries/queriesProfile";

import { useMutation } from "@apollo/client";

//  оплата за абонемент
const PayByAccountSubscription = ({ buttonTitle, finishFunc, onResponse, clas = "", familyProfileId, promocode }) => {
  const router = useRouter();
  const {
    query: { templateId, cardId },
  } = router;

  const [payCardFromAccount] = useMutation(PAY_CARD_FROM_ACCOUNT, {
    // refetchQueries: [`ClientEventDetail`],
    refetchQueries: [{ query: GET_PROFILE }],
  });

  const onClick = useCallback(() => {
    payCardFromAccount({
      variables: {
        ...(cardId ? { cardId: +cardId } : { templateId: +templateId }),
        ...(familyProfileId && { familyProfileId: +familyProfileId }),
        ...(promocode && { promocode }),
      },
    }).then(
      () => {
        finishFunc();
        onResponse({ isSuccess: true, message: "", isPayment: true });
      },
      ({ graphQLErrors, message }) => {
        if (graphQLErrors?.[0]?.result == 5) return;
        onResponse({ isSuccess: false, message });
      }
    );
  }, [templateId, cardId, familyProfileId, promocode, onResponse]);

  return (
    <div className="payment-card__button-block" style={clas ? { maxWidth: "none", paddingRight: "10px", paddingLeft: "10px" } : {}}>
      <button className={clas ? clas : `payment-card__btn`} onClick={onClick} type="button">
        {buttonTitle}
      </button>
    </div>
  );
};

export default PayByAccountSubscription;
