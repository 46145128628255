import clsx from "clsx";
import React from "react";

import { GO_BACK_SVG } from "../../../constants/svgConstants";

import { prevButton } from "./BackButton.module.scss";

export const BackButton = ({
  title,
  onClick = () => null,
  additionalClass = "",
  type = "button",
}) => {
  return (
    <button
      className={clsx(prevButton, {
        [additionalClass]: !!additionalClass,
      })}
      onClick={onClick}
      type={type}
    >
      {GO_BACK_SVG} {title}
    </button>
  );
};
