import { ADD_OPTION, REMOVE_OPTION, RESET_OPTIONS } from "./types";

const optionData = (state, { type, option, optionId }) => {
  switch (type) {
    case ADD_OPTION:
      return [...state, option];

    case REMOVE_OPTION:
      return state.filter(({ id }) => id !== optionId);
    case RESET_OPTIONS:
      return [];
    default:
      return state;
  }
};

export default optionData;
