import React from "react";
import { useToggle } from "../../../../../../hooks";

import ConfirmPhone from "../../../../../common/SignUpPhone/ConfirmPhone";
import { ConfirmEmail } from "../../../../../common/SignUpPhone/ConfirmEmail";

export const IS_PHONE_MODAL = "phone";
export const IS_EMAIl_MODAL = "email";

const PaymentWithModal = ({
  buttonTitle,
  buttonChild,
  // isProfileModal = false,
  isConfirmModal,
  isEmailConfirmModal,
  children,
}) => {
  // const [showApproveNumber, setShowApproveNumber] = useState(false);
  const [showApproveNumber, toggle, onClose, showApproveModal] =
    useToggle(false);

  return (
    <>
      {showApproveNumber &&
        (isConfirmModal ? (
          <ConfirmPhone
            buttonChild={buttonChild}
            onClose={onClose}
            isEmailConfirmModal={isEmailConfirmModal}
          />
        ) : (
          isEmailConfirmModal && <ConfirmEmail onClose={onClose} />
        ))}

      <div className="payment-card">
        <div className="payment-card__wrapper">
          {isConfirmModal || isEmailConfirmModal ? (
            <div className="payment-card__button-block">
              <button className="payment-card__btn" type="button" onClick={showApproveModal}>
                {buttonTitle}
              </button>
            </div>
          ) : (
            buttonChild({})
          )}
          {children}
        </div>
      </div>
    </>
  );
};

export default PaymentWithModal;
