import { useEffect } from "react";
import { useRouter } from "next/router";

const useSmoothScroll = (
  pageRefs = {},
  refName = "",
  { time = 500, position = "top" } = {}
) => {
  const router = useRouter();
  const { query } = router;

  useEffect(() => {
    if (!refName || !pageRefs[refName]) return;

    const currentBlock = pageRefs[refName].current;

    // currentBlock.scrollIntoView({ block: 'center' });
    const scrollTimer = setTimeout(
      () => SmoothVerticalScrolling(currentBlock, time, position),
      0.01
    );

    function SmoothVerticalScrolling(e, time, where) {
      var eTop = e.getBoundingClientRect().top; //-30
      var eAmt = eTop / 100;
      var curTime = 0;
      let timeId = null;
      while (curTime <= time) {
        timeId = setTimeout(SVS_B, curTime, eAmt, where);
        curTime += time / 100;
      }
      clearTimeout(timeId);
    }

    function SVS_B(eAmt, where) {
      if (where == "center") return window.scrollBy(0, eAmt / 2);

      if (where == "top") window.scrollBy(0, eAmt);
    }
    if (query) window.history.replaceState({}, document.title);

    return () => clearTimeout(scrollTimer);
  }, [pageRefs, refName, time, position]);
};

export { useSmoothScroll };
