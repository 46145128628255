import React, { useMemo } from "react";

import PayByAccount from "../PayButtons/PayByAccount";
import PayByBook from "../PayButtons/PayByBook";

import PayByAccountSubscription from "../PayButtons/PayByAccountSubscription";
import PayByRequest from "../PayButtons/PayByRequest";
import { useTranslation } from "next-i18next";
import { PayByOnline } from "../PayButtons/PayByOnline";

export default function useCreatePaymentCard({
  isSubscription = false,
  visitData = {},
  options = [],
  familyProfileId,
  promocode,
  onResponse = () => null,
}) {
  const [t, { language }] = useTranslation();

  const paymentCards = useMemo(() => {
    const {
      // id,
      // cardId,
      // eventId,
      visitId,
      payments = [],
    } = visitData;

    const optionsId = options.map(({ id }) => id);

    const paymentsByType = {
      //Enroll
      "-1": (buttonTitle, subtitle) => ({
        id: 1,
        subtitle,
        buttonTitle,
        buttonChild: ({ clas, finishFunc = () => {}, ...args } = {}) => (
          <PayByRequest
            buttonTitle={buttonTitle}
            finishFunc={finishFunc}
            onResponse={onResponse}
            familyProfileId={familyProfileId}
            promocode={promocode}
            options={optionsId}
            clas={clas}
            {...args}
          />
        ),
      }),
      //Online
      18: (buttonTitle) => ({
        id: 2,
        subtitle: t("pay_online_subtitle"),
        subtitleLink: () => null,

        buttonTitle,
        buttonChild: ({ clas, ...args } = {}) => (
          <PayByOnline
            familyProfileId={familyProfileId}
            buttonTitle={buttonTitle}
            clas={clas}
            options={optionsId}
            visitId={visitId}
            promocode={promocode}
            onResponse={onResponse}
            isSubscription={isSubscription}
            {...args}
          />
        ),
        // isTitleImage: true,
        // subtitle: agreementServiceText,
        // activeSubtitle: agreementServiceWord,

        // subtitleLink: () => {
        //   const pathname = `/club/${club}/offer`;
        //   router.push(
        //     {
        //       pathname,
        //       query: {
        //         ...(isSubscription && { templateId: id }),
        //         cardId: cardId,
        //         eventId: !isSubscription ? (eventId ? eventId : id) : null,
        //       },
        //     },
        //     pathname
        //   );
        // },
      }),
      //Account
      4: (buttonTitle, subtitle) => ({
        id: 4,
        subtitle,
        subtitleLink: () => null,
        buttonTitle,

        buttonChild: ({ clas, finishFunc = () => {}, ...args } = {}) =>
          isSubscription ? (
            <PayByAccountSubscription
              buttonTitle={buttonTitle}
              finishFunc={finishFunc}
              onResponse={onResponse}
              clas={clas}
              familyProfileId={familyProfileId}
              promocode={promocode}
              {...args}
            />
          ) : (
            <PayByAccount
              buttonTitle={buttonTitle}
              finishFunc={finishFunc}
              onResponse={onResponse}
              visitId={visitId}
              familyProfileId={familyProfileId}
              promocode={promocode}
              options={optionsId}
              clas={clas}
              {...args}
            />
          ),
      }),
      //Book
      5: (buttonTitle, subtitle) => ({
        id: 5,
        subtitle,
        buttonTitle,
        buttonChild: ({ clas, finishFunc = () => {}, ...args } = {}) => (
          <PayByBook
            buttonTitle={buttonTitle}
            finishFunc={finishFunc}
            onResponse={onResponse}
            familyProfileId={familyProfileId}
            promocode={promocode}
            options={optionsId}
            visitId={visitId}
            clas={clas}
            {...args}
          />
        ),
      }),
    };

    return payments
      .map(({ choice: paymentNumber, title, description: subtitle }) => {
        return paymentsByType[paymentNumber]?.(title, subtitle);
      })
      .filter(Boolean);
  }, [
    isSubscription,
    visitData,
    options,
    familyProfileId,
    onResponse,
    language,
    promocode,
  ]);

  return paymentCards;
}
