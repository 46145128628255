import React, { memo, useCallback, useRef, useState } from "react";

import { useTranslation } from "next-i18next";

import { PromocodeInput } from "./PromocodeInput";
import { GET_CLIENT_CARD_TEMPLATE_DETAIL } from "../../../graphql/queries/queriesCard";
import { useLazyQuery } from "@apollo/client";
import { CLOSE_WHITE_SVG } from "../../../constants/svgConstants";

export const PromocodeBlock = memo(({ promocode, setPromocode, setCardData = () => null, familyId, templateId, isSubscription }) => {
  const [t] = useTranslation();

  const [{ errorMsg }, setState] = useState({
    errorMsg: "",
  });

  const inputRef = useRef("");

  const [getSubscription] = useLazyQuery(GET_CLIENT_CARD_TEMPLATE_DETAIL, {
    fetchPolicy: "cache-and-network",
  });

  const onClick = useCallback(
    ({ isClose = false } = {}) => {
      setState({ errorMsg: "" });

      let isError = false;
      let value = inputRef.current?.value;

      if (isClose) {
        if (inputRef) inputRef.current.value = "";

        return;
      }

      if (!promocode && !value?.trim()) isError = true;

      if (isError) return setState({ errorMsg: t("required_field") });

      const promocodeValue = promocode ? "" : value;

      if (!isSubscription) return setPromocode(promocodeValue);

      getSubscription({
        variables: {
          id: +templateId,
          ...(familyId && { profileId: +familyId }),
          promocode: promocodeValue,
        },
        onCompleted: ({ cardTemplate: cardData }) => {
          setPromocode(promocodeValue);

          setCardData({ cardData, isLoading: false });
        },
        onError: ({ graphQLErrors, message }) => {
          if (graphQLErrors?.[0]?.result === 0) setState({ errorMsg: message });
        },
      });
    },
    [promocode, familyId, isSubscription]
  );

  return (
    <>
      <div className="promocode">
        {promocode ? (
          <>
            <div className="promocode__text" onClick={onClick}>
              <span>{`Your Promocode is ${promocode}`}</span>
              <span className="promocode__remove-button">{CLOSE_WHITE_SVG}</span>
            </div>
          </>
        ) : (
          <>
            <PromocodeInput
              inputRef={inputRef}
              placeholderValue={t("enter_promocode")}
              errorMessage={errorMsg}
              classNameWrapper="promocode__input-wrapper"
              onClick={onClick}
            />
            {errorMsg && (
              <div className="sign-up-section__input-error-message">
                <p>{errorMsg}</p>
              </div>
            )}
          </>
        )}
      </div>
      <style jsx global>{`
        @import "./PromocodeBlock.scss?1";
      `}</style>
    </>
  );
});
