import { gql } from "@apollo/client";

export const GET_CARD_DETAIL = gql`
  query CardDetail($cardId: Int!) {
    card(id: $cardId) {
      id
      paidChoice
      priceInfo {
        price: priceFloat
        regularPrice: regularPriceFloat
        bonusTitle
      }
      template {
        id
        title
        subtitle
        group {
          id
          title
          order
        }
        description
        descriptionHtml
        amount
        duration
      }
      payments {
        choice
        title
        description
      }
    }
  }
`;
// statusTitle
const cardsArgs = `
id
dueDate
activated
amount
transfer
pauses
paused
pausedDuration 
pausedDueDate 
status
paidStatusShort
paidChoice
priceInfo {
  price:priceFloat
  bonusTitle
}

guestVisitAmount

user {
  name
  relationTitle
}
template{
  id
  title
  subtitle
  group {
    id
    title
    order
  }
  order

}`;

// export const GET_CARDS = (type) => {
//   const isActive = type === cardTypes.cardsActive;
//   return gql`query Cards ($profileId:Int) {
//   cards: ${type} (familyProfileId:$profileId) {

//     ${isActive ? ["freezeEnabled", "prolongationEnabled"].join(" ") : ""}
//     ${cardsArgs}
//   }
// }`;
// };

export const GET_ACTIVE_CARDS = gql`
  query Cards($profileId: Int) {
    cards: cardsActive(familyProfileId: $profileId) {
      freezeEnabled
      prolongationEnabled
      ${cardsArgs}
    }
  }
`;

export const GET_ARCHIVE_CARDS = gql`
  query Cards($profileId: Int) {
    cards: cardsArchive(familyProfileId: $profileId) {
      ${cardsArgs}
    }
  }
`;

export const CARD_TEMPLATES_GROUP = gql`
  query CardTemplateGroups {
    cardTemplateGroups {
      id
      title
      order
    }
  }
`;

export const CARD_TEMPLATES = gql`
  query CardTemplates {
    cardTemplates {
      id
      title
      subtitle

      priceInfo {
        price
      }
      # currency
      order
      amount
      pauses
      duration
      group {
        id
        title
      }
      # promocodePrice
      # loyaltyPrice
    }
  }
`;

export const GET_CARD_TEMPLATES_BY_GROUP = gql`
  query CardTemplates($groupId: Int!) {
    cardTemplates(groupId: $groupId) {
      id
      title
      subtitle

      priceInfo {
        price
        bonusTitle
        regularPrice
      }
      order
      amount
      pauses
      duration
      group {
        id
        title
      }
    }
  }
`;

export const GET_CARD_OFFER = gql`
  query CardDetail($id: Int!) {
    offerData: card(id: $id) {
      id
      offer
    }
  }
`;

export const GET_CARD_TEMPLATE_OFFER = gql`
  query CardTemplateDetail($id: Int!) {
    offerData: cardTemplate(id: $id) {
      id
      offer
    }
  }
`;

export const GET_CLIENT_CARD_TEMPLATE_DETAIL = gql`
  query CardTemplateDetail(
    $id: Int!
    $promocode: String # $profileId: Int!
  ) {
    # familyProfileId: $profileId
    cardTemplate(id: $id, promocode: $promocode) {
      id
      title
      subtitle

      phoneConfirmationRequired
      emailConfirmationRequired
      group {
        id
        title
        order
      }
      promocodesEnabled
      # promocode {
      #   id
      #   code
      # }
      description
      descriptionHtml
      priceInfo {
        price: priceFloat
        regularPrice: regularPriceFloat
        bonusTitle
      }

      amount
      duration
      payments {
        choice
        title
        description
      }
    }
  }
`;

export const GET_CARD_TEMPLATE_DETAIL = gql`
  query CardTemplateDetail($id: Int!) {
    cardTemplate(id: $id) {
      id
      title
      subtitle

      group {
        id
        title
        order
      }

      description
      descriptionHtml
      priceInfo {
        price: priceFloat
        regularPrice: regularPriceFloat
        bonusTitle
      }

      amount
      duration
    }
  }
`;
