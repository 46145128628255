import { gql } from "@apollo/client";

export const PAY_VISIT_FROM_ACCOUNT = gql`
  mutation PayVisitFromAccount($familyProfileId: Int, $event: Int!, $visitId: Int, $origin: Int!, $options: JSONString, $promocode: String) {
    payVisitFromAccount(familyProfileId: $familyProfileId, event: $event, visitId: $visitId, origin: $origin, options: $options, promocode: $promocode) {
      visit {
        id
      }
    }
  }
`;

export const BOOK_VISIT = gql`
  mutation BookVisit($familyProfileId: Int, $event: Int!, $visitId: Int, $origin: Int!, $options: JSONString, $promocode: String) {
    bookVisit(familyProfileId: $familyProfileId, event: $event, visitId: $visitId, origin: $origin, options: $options, promocode: $promocode) {
      visit {
        id
      }
    }
  }
`;

export const PAY_VISIT_BY_CARD = gql`
  mutation PayVisitByCard($familyProfileId: Int, $event: Int!, $origin: Int!, $visitId: Int, $card: Int!) {
    payVisitByCard(familyProfileId: $familyProfileId, event: $event, visitId: $visitId, origin: $origin, card: $card) {
      visit {
        id
      }
    }
  }
`;

export const PAY_CARD_FROM_ACCOUNT = gql`
  mutation PayCardFromAccount($cardId: Int, $templateId: Int, $familyProfileId: Int, $promocode: String) {
    payCardFromAccount(cardId: $cardId, templateId: $templateId, familyProfileId: $familyProfileId, promocode: $promocode) {
      card {
        id
      }
    }
  }
`;

// export const SET_CARD_PAYMENT_ORDER = gql`
//   mutation SetCardPaymentOrderProcessing($cardId: Int!, $paid: Int!) {
//     paymentOrder: setCardPaymentOrderProcessing(cardId: $cardId, paid: $paid) {
//       ok
//     }
//   }
// `;

// export const SET_VISIT_PAYMENT_ORDER = gql`
//   mutation SetVisitPaymentOrderProcessing($visitId: Int!, $paid: Int!) {
//     paymentOrder: setVisitPaymentOrderProcessing(
//       visitId: $visitId
//       paid: $paid
//     ) {
//       ok
//     }
//   }
// `;
