import React, { useMemo, useState } from "react";
import { AlertBlock } from "../AlertBlock";

import Window5 from "../SignUp/Window5";
import Window6 from "../SignUp/Window6";
import Window4 from "../SignUp/Window4";

function ConfirmEmail({ onClose }) {
  const [email, setEmail] = useState("");
  const [activeWindow, setActiveWindow] = useState("window4");

  const { emailEnter, confirmEmail } = useMemo(() => {
    return {
      emailEnter: () => setActiveWindow("window4"),
      confirmEmail: () => setActiveWindow("window6"),
    };
  }, []);

  return (
    <AlertBlock onClose={onClose}>
      {(activeWindow === "window4" && (
        <Window4
          setActiveWindow={setActiveWindow}
          setEmail={setEmail}
          email={email}
        />
      )) ||
        (activeWindow === "window5" && (
          <Window5
            email={email}
            setActiveWindow={confirmEmail}
            onClickPrevButton={emailEnter}
          />
        )) ||
        (activeWindow === "window6" && (
          <Window6 onClickPrevButton={emailEnter} setActiveWindow={onClose} />
        ))}
    </AlertBlock>
  );
}

export { ConfirmEmail };
