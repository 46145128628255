import React, { memo, useCallback, useMemo } from "react";
import PropTypes from "prop-types";

import PayByCard from "../PayButtons/PayByCard";
import PaymentWithModal from "../Payment/PaymentWithModal";
import { Trans, useTranslation } from "next-i18next";
import { PAYMENT_INFO } from "../constants/languageConstants";

const PaymentSubCard = memo(
  ({
    id: card,
    secondTitle,
    description,
    activated,
    amount,
    dueDate,
    onResponse,
    familyProfileId,
    isConfirmModal,
    isEmailConfirmModal,
    visitId,
  }) => {
    const [t] = useTranslation();

    const { amountTitle, dateTitle } = useMemo(() => {
      const endDate = new Date(dueDate),
        endDayDate = ("0" + endDate.getDate()).slice(-2),
        endMonthDate = ("0" + (endDate.getMonth() + 1)).slice(-2),
        endYearDate = endDate.getFullYear(),
        dateTitle = `${t(
          "expiration_date"
        )} ${endDayDate}.${endMonthDate}.${endYearDate}`;

      return {
        amountTitle: PAYMENT_INFO(amount),
        dateTitle,
      };
    }, [amount, dueDate]);

    const buttonChild = useCallback(
      ({ clas, finishFunc = () => {}, ...args }) => {
        return (
          <PayByCard
            buttonTitle={t("pay_by_card")}
            finishFunc={finishFunc}
            onResponse={onResponse}
            familyProfileId={familyProfileId}
            clas={clas}
            card={card}
            visitId={visitId}
            {...args}
          />
        );
      },
      [onResponse, familyProfileId, card, visitId]
    );

    return (
      <PaymentWithModal
        buttonTitle={t("pay_by_card")}
        buttonChild={buttonChild}
        isConfirmModal={isConfirmModal}
        isEmailConfirmModal={isEmailConfirmModal}
      >
        <div className="payment-card__content">
          <div className="payment-card__title-block">
            <p className="payment-card__title">
              {t("subscription")} {card}
            </p>
            {secondTitle && (
              <p className="payment-card__title">{secondTitle}</p>
            )}
          </div>

          {/* {secondTitle && (
            <div className="payment-card__second-title-block ">
              <p className="payment-card__title">{secondTitle}</p>
            </div>
          )} */}

          <div className="payment-card__content-inner">
            {description && (
              <div className="payment-card__description">{description}</div>
            )}

            {!!(amountTitle || dateTitle) && (
              <div className="payment-card__description">
                <p className="payment-card__description-amount">
                  <Trans i18nKey={amountTitle} values={{ amount }} />
                </p>

                <p className="payment-card__description-date">
                  {activated ? dateTitle : t("not_activated_subscription")}
                </p>
              </div>
            )}
          </div>
        </div>
      </PaymentWithModal>
    );
  }
);

PaymentSubCard.propTypes = {
  id: PropTypes.number,
  secondTitle: PropTypes.string,
  description: PropTypes.string,
  amount: PropTypes.number,

  buttonTitle: PropTypes.string,
  dueDate: PropTypes.string,
  finishFunc: PropTypes.func,
  activeClub: PropTypes.string,
  allProfiles: PropTypes.array,
};

PaymentSubCard.defaultProps = {
  id: null,
  secondTitle: "",
  description: "",
  amount: null,

  buttonTitle: "",
  dueDate: "",
  finishFunc: () => {},
  activeClub: "",
  allProfiles: [],
};

export default PaymentSubCard;
