import React, { useCallback } from "react";
import { useRouter } from "next/router";
import { BOOK_VISIT } from "../../../../../../graphql/mutations/mutationPay";

import { useMutation } from "@apollo/client";

const origin = 2;

function PayByBook({ buttonTitle, finishFunc, onResponse, clas = "", familyProfileId, visitId, eventIdDefault, options, promocode }) {
  const router = useRouter();
  const {
    query: { eventId },
  } = router;

  const event = eventIdDefault || eventId;

  const [bookVisit] = useMutation(BOOK_VISIT, {
    fetchPolicy: "no-cache",
    refetchQueries: [`ClientEventDetail`],
  });

  const onClick = useCallback(() => {
    bookVisit({
      variables: {
        origin,
        event: +event,
        ...(options?.length && { options: JSON.stringify(options) }),
        ...(visitId && { visitId: +visitId }),
        ...(familyProfileId && { familyProfileId: +familyProfileId }),
        ...(promocode && { promocode }),
      },
    }).then(
      () => {
        finishFunc();
        onResponse({ isSuccess: true, message: "" });
      },
      ({ graphQLErrors, message }) => {
        if (graphQLErrors?.[0]?.result == 5) return;
        onResponse({ isSuccess: false, message });
      }
    );
  }, [event, familyProfileId, promocode, visitId, options]);

  return (
    <div className="payment-card__button-block" style={clas ? { maxWidth: "none", paddingRight: "10px", paddingLeft: "10px" } : {}}>
      <button className={clas ? clas : `payment-card__btn`} onClick={onClick} type="button">
        {buttonTitle}
      </button>
    </div>
  );
}

export default PayByBook;
