import { useState, useEffect } from "react";

export const useMediaQuery = (query = "(min-width:768px)") => {
  const getMatches = (query) => {
    if (typeof window !== "undefined") {
      return window.matchMedia(query).matches;
    }
    return false;
  };
  const [isMatch, setMatch] = useState(getMatches(query));

  const handleChange = () => {
    setMatch(getMatches(query));
  };
  // useCallback(
  //   (e) => {
  // setMatch(!!e.matches);

  // },
  // [query]
  // );

  useEffect(() => {
    const matchMedia = window.matchMedia(query);

    // if (matchMedia.matches) setMatch(true);

    handleChange();

    if (matchMedia.addListener) {
      matchMedia.addListener(handleChange);
    } else {
      matchMedia.addEventListener("change", handleChange);
    }

    return () => {
      matchMedia.removeListener
        ? matchMedia.removeListener(handleChange)
        : matchMedia.removeEventListener("change", handleChange);
    };
  }, [query]);

  return isMatch;
};
