import { useEffect, useState } from 'react';

function useSticky() {
  const [sticky, setSticky] = useState(false);

  const handleScroll = () => {
    if (window.pageYOffset > 0 && sticky) return;

    setSticky(!sticky);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [handleScroll]);

  return sticky;
}

export { useSticky };
