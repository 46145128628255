import React, { useMemo, memo } from "react";

import clsx from "clsx";
import { DropDown } from "../../../../common/DropDown/DropDown";

export const ChooseProfile = memo(
  ({
    title,
    items,
    activeId,
    onClick,
    locale,
    className,
    titleClassName,
    dropdownClassName,
  }) => {
    const dropItems = useMemo(
      () =>
        items.map(({ id, firstName, lastName }) => ({
          id,
          title: `${lastName} ${firstName}`,
        })),
      [items, locale]
    );

    return (
      <div
        className={clsx("main-title-block", {
          [className]: !!className,
        })}
      >
        <h2
          className={clsx("subtitle", {
            [titleClassName]: !!titleClassName,
          })}
        >
          {title}
        </h2>

        <DropDown
          activeId={activeId}
          dropItems={dropItems}
          changeActiveId={(id) => onClick(id)}
          className={dropdownClassName}
        />
      </div>
    );
  }
);
