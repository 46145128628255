import React, { useCallback } from "react";
import { PAY_VISIT_FROM_ACCOUNT } from "../../../../../../graphql/mutations/mutationPay";
import { useMutation } from "@apollo/client";
import { useRouter } from "next/router";
import { GET_PROFILE } from "../../../../../../graphql/queries/queriesProfile";

const origin = 2;

function PayByAccount({ buttonTitle, finishFunc, onResponse, clas = "", familyProfileId, visitId, eventIdDefault, options, promocode }) {
  const router = useRouter();
  const {
    query: { eventId },
  } = router;

  const event = eventIdDefault || eventId;

  const [payVisitFromAccount] = useMutation(PAY_VISIT_FROM_ACCOUNT, {
    refetchQueries: [`ClientEventDetail`, { query: GET_PROFILE }],
  });

  const onClick = useCallback(() => {
    payVisitFromAccount({
      variables: {
        origin,
        event: +event,
        ...(options?.length && { options: JSON.stringify(options) }),
        ...(visitId && { visitId: +visitId }),
        ...(familyProfileId && { familyProfileId: +familyProfileId }),
        ...(promocode && { promocode }),
      },
    }).then(
      () => {
        finishFunc();
        onResponse({ isSuccess: true, message: "" });
      },
      ({ graphQLErrors, message }) => {
        if (graphQLErrors?.[0]?.result == 5) return;
        onResponse({ isSuccess: false, message });
      }
    );
  }, [event, familyProfileId, visitId, options, promocode, onResponse]);

  return (
    <div className="payment-card__button-block" style={clas ? { maxWidth: "none", paddingRight: "10px", paddingLeft: "10px" } : {}}>
      <button className={clas ? clas : `payment-card__btn`} onClick={onClick} type="button">
        {buttonTitle}
      </button>
    </div>
  );
}

export default PayByAccount;
