import { useMemo, useReducer, createContext, useContext } from "react";
import optionData from "./optionReducer";
import { ADD_OPTION, REMOVE_OPTION, RESET_OPTIONS } from "./types";

const OptionContext = createContext({
  addOption: () => null,
  removeOption: () => null,
});

export default OptionContext;

export function OptionProvider({ children }) {
  const option = useProviderOption();
  return (
    <OptionContext.Provider value={option}>{children}</OptionContext.Provider>
  );
}

const initialState = [];

const useProviderOption = () => {
  const [state, dispatch] = useReducer(optionData, initialState);

  const { addOption, removeOption, resetOption } = useMemo(
    () => ({
      addOption: (option) =>
        dispatch({
          type: ADD_OPTION,
          option,
        }),
      removeOption: (optionId) =>
        dispatch({
          type: REMOVE_OPTION,
          optionId,
        }),
      resetOption: () => dispatch({ type: RESET_OPTIONS }),
    }),
    []
  );

  return { state, addOption, removeOption, resetOption };
};

export const useOption = () => useContext(OptionContext);
