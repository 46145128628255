import React, { useMemo } from 'react';
import { useToggle } from '../../../hooks/useToggle';
import clsx from 'clsx';

import { DROP_SVG } from '../../../constants/svgConstants';

export const DropDown = ({
  className,
  dropItems,
  activeId,
  changeActiveId,
}) => {
  const [show, toggle, off] = useToggle();

  const activeTitle = useMemo(() => {
    const item = dropItems.find(({ id }) => +id === +activeId);

    return item?.title ?? dropItems[0].title;
  }, [activeId, dropItems]);

  return (
    <div className={clsx('dropdown', { [className]: !!className })}>
      <div
        className={clsx('dropdown__title-block', {
          'dropdown__title-block--active': show,
        })}
        onClick={toggle}
      >
        <span className="dropdown__text">{activeTitle}</span>
        <span className="dropdown__svg">{DROP_SVG}</span>
      </div>

      {show && (
        <div className="dropdown__list">
          <div className="dropdown__list-inner">
            {dropItems.map(({ id, title }) => (
              <div
                key={id}
                className={clsx('dropdown-item', {
                  'dropdown-item--active': id === activeId,
                })}
                onClick={() => {
                  changeActiveId(id);
                  off();
                }}
              >
                {title}
              </div>
            ))}
          </div>
        </div>
      )}

      <style jsx global>{`
        @import './DropDown.scss?';
      `}</style>
    </div>
  );
};
