export const DATE_TITLE = (amount) => {
  const multiplicity = amount % 10;

  return multiplicity === 1
    ? "one_day"
    : multiplicity > 1 && multiplicity < 5
      ? (amount > 1 && amount < 5) || amount > 15
        ? "few_days"
        : "lot_days"
      : "lot_days";
};
