import React, { memo, useEffect } from "react";
import PropTypes from "prop-types";

import PaymentCard from "../PaymentCard/PaymentCard";
import PaymentSubCard from "../PaymentSubCard/PaymentSubCard";

import useCreatePaymentCard from "./useCreatePaymentCard";

import { useOption } from "../context/optionContext";

import { OptionsPaymentBlock } from "../OptionsBlock/OptionsPaymentBlock";

import { SingleWrapper } from "../../../../../common/SimpleWrapper/SingleWrapper";

import { useUserData } from "../../../../../../utils/apollo/reactivities/useReactivities";

const PaymentBlock = memo(
  ({
    className = "",
    isAdditionalPayment = false,
    visitData,
    cards,
    isSubscription,
    options = [],
    optionsTitle = "",
    familyProfileId,
    onResponse,
    promocode,
  }) => {
    const { emailConfirmed, phoneConfirmed } = useUserData();

    const { state, resetOption } = useOption();

    useEffect(() => {
      if (isAdditionalPayment) resetOption();
    }, [isAdditionalPayment]);

    const paymentCards = useCreatePaymentCard({
      isSubscription,
      visitData,
      options: state,
      familyProfileId,
      onResponse,
      promocode,
    });

    const { phoneConfirmationRequired, emailConfirmationRequired } = visitData;

    const isConfirmModal = phoneConfirmationRequired && !phoneConfirmed;
    const isEmailConfirmModal = emailConfirmationRequired && !emailConfirmed;

    return (
      <SingleWrapper className={className}>
        {!!options.length && (
          <OptionsPaymentBlock
            options={options}
            visitOptions={state}
            title={optionsTitle}
          />
        )}
        {paymentCards.map(
          ({
            id,
            title,
            isTitleImage,
            subtitle,
            activeSubtitle,
            description,
            buttonTitle,
            buttonChild,
            subtitleLink,
          }) => (
            <PaymentCard
              key={id}
              title={title}
              subtitle={subtitle}
              isTitleImage={isTitleImage}
              activeSubtitle={activeSubtitle}
              description={description}
              buttonTitle={buttonTitle}
              buttonChild={buttonChild}
              subtitleLink={subtitleLink}
              isConfirmModal={isConfirmModal}
              isEmailConfirmModal={isEmailConfirmModal}
            />
          )
        )}

        {/* Тренировки оплатой с абонемента */}
        {cards.map(
          ({
            id,
            activated,
            dueDate,
            amount,
            template: { title, subtitle },
          }) => (
            <PaymentSubCard
              key={id}
              id={+id}
              secondTitle={title}
              description={subtitle}
              activated={activated}
              amount={amount}
              dueDate={dueDate}
              visitId={visitData.visitId}
              familyProfileId={familyProfileId}
              isConfirmModal={isConfirmModal}
              isEmailConfirmModal={isEmailConfirmModal}
              onResponse={onResponse}
            />
          )
        )}

        <style jsx global>{`
          @import "./PaymentBlock.scss?3";
        `}</style>
      </SingleWrapper>
    );
  }
);

PaymentBlock.propTypes = {
  payments: PropTypes.array,
  visitData: PropTypes.shape({
    visitId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  }),
  cards: PropTypes.array,
  isSubscription: PropTypes.bool,
};

PaymentBlock.defaultProps = {
  visitData: {},
  payments: [],
  cards: [],

  isSubscription: false,
};

export default PaymentBlock;
