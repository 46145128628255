import { useMemo, useState } from "react";

const UseFilter = (data = [], filterBy, initialValue = "") => {
  const [filter, setFilter] = useState(initialValue);
  // const ref = useRef(false);

  // useEffect(() => {
  //   if (ref.current) setFilter(initialValue);
  //   else ref.current = true;
  // }, [initialValue]);

  const filteredData = useMemo(() => {
    if (filter === "") return data;

    return data.filter((i) =>
      i[filterBy].toLowerCase().includes(filter.toLowerCase())
    );
  }, [filter, data, filterBy]);

  return [filter, setFilter, filteredData];
};

export { UseFilter };
