import React, { memo } from "react";
import PropTypes from "prop-types";

import PaymentWithModal from "../Payment/PaymentWithModal";

const PaymentCard = memo(
  ({
    subtitle,
    // title,
    // isTitleImage,
    // activeSubtitle,
    buttonTitle,
    buttonChild,
    description,
    subtitleLink,
    isConfirmModal,
    isEmailConfirmModal,
  }) => {
    return (
      <PaymentWithModal
        buttonTitle={buttonTitle}
        buttonChild={buttonChild}
        isConfirmModal={isConfirmModal}
        isEmailConfirmModal={isEmailConfirmModal}
        // isProfileModal
      >
        <div className="payment-card__content">
          {!!subtitle && (
            <>
              <div
                className="payment-card__subtitle"
                onClick={subtitleLink}
                dangerouslySetInnerHTML={{
                  __html: subtitle,
                }}
                //   `${subtitle} <span
                //   class="payment-card__subtitle_active"
                // >
                // ${activeSubtitle}
                // </span>`,
                // }}
              ></div>
            </>
          )}

          {description && (
            <div className="payment-card__description">{description}</div>
          )}
        </div>
      </PaymentWithModal>
    );
  }
);

PaymentCard.propTypes = {
  title: PropTypes.string,
  isTitleImage: PropTypes.bool,
  subtitle: PropTypes.string,
  activeSubtitle: PropTypes.string,
  buttonTitle: PropTypes.string,
  buttonChild: PropTypes.func,
  description: PropTypes.string,
  allProfiles: PropTypes.array,
  subtitleLink: PropTypes.func,
};

PaymentCard.defaultProps = {
  title: "",
  isTitleImage: false,
  subtitle: "",
  activeSubtitle: "",
  buttonTitle: "",
  buttonChild: () => {},
  description: "",
  allProfiles: [],
  subtitleLink: () => {},
};

export default PaymentCard;
