import Image from "next/image";
import React from "react";

export const OptionCard = ({ image, title, price, children }) => {
  return (
    <div className="option-card">
      <div className="option-card__info">
        <div className="avatar-wrapper option-card__avatar">
          {image && (
            <Image
              src={image}
              className="avatar__placeholder avatar--rounded"
              fill
              alt={title}
            />
          )}
        </div>
        <div className="option-card__content">
          <div className="option-card__title">{title}</div>
          <div className="option-card__price">{price}</div>
        </div>
      </div>
      {children}
    </div>
  );
};
