import { useState, useCallback } from "react";

export const useForm = ({
  initialState = {},
  validate,
  onSubmit = () => null,
}) => {
  const [values, setValues] = useState(initialState);
  const [touched, setTouched] = useState({});
  // const [errors, setErrors] = useState({});

  const handleInputChange = useCallback(({ target, currentValue }) => {
  
    const { name, value = currentValue } = target;

    setValues((s) => ({
      ...s,
      [name]:  value,
    }));
  }, []);

  const handleBlur = useCallback(({ target }) => {
    const { name } = target;
    setTouched((prevTouched) => ({ ...prevTouched, [name]: true }));
  }, []);

  const errors = validate ? validate(values) : {};

  // useEffect(() => {
  //   const errors = validate ? validate(values) : {};
  //   setErrors(errors);
  // }, [values, validate]);

  const valid = Object.keys(errors).length === 0;

  const handleSubmit = useCallback(
    (e) => {
      e.preventDefault();
      // const errors = validate ? validate(values) : {};

      // const isValid = valid(errors);

      if (valid) onSubmit(values);

      // else setErrors(errors);
    },
    [values, valid]
  );

  return {
    values,
    errors,
    touched,
    handleInputChange,
    handleSubmit,
    handleBlur,
  };
};
